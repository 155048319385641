import React, { Fragment, useContext } from 'react';
import SliderMainParticle from '../components/SliderMainParticle';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/CarouselCollection';
import CarouselNew from '../components/CarouselNew';
import AuthorList from '../components/authorList';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';

import { GlobalContext } from "components/app";
import { NavLink } from 'react-bootstrap';
import { ShowMinterButton } from 'components/menu/header';
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;


const Homeone = () => {
  const { isLogin, setIsLogin } = useContext(GlobalContext);
  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron no-bg">
        <SliderMainParticle />
      </section>

      {
        isLogin &&
        <Fragment>
           <ShowMinterButton/>
           <br/>
           <br/><br/><br/>
          <section className='container no-top'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <h2 className='style-2'>Browse Category</h2>

                <div className="small-border"></div>
              </div>
            </div>
            <div className='container px-0'>
              <div className="row">
                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Characters</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Game Assets</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Music</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Memberships</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>VIP PASS</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Community Tickets</h4>
                  </span>
                </div>
              </div>
            </div>
          </section>
         
          <section className='container no-top no-bottom'>
            <div className='row'>
              <div className="spacer-double"></div>
              <div className='col-lg-12 mb-2'>
                <h2>Items On Sale</h2>
              </div>
            </div>
            <CarouselNew />
          </section>

        </Fragment>
      }



      {/*<section className='container no-top no-bottom'>
        <div className="row">
            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/1.png" alt="" className="mb20"/>
                    <h4>Metamask</h4>
                </span>
            </div>

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/2.png" alt="" className="mb20"/>
                    <h4>Bitski</h4>
                </span>
            </div>       

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/3.png" alt="" className="mb20"/>
                    <h4>Fortmatic</h4>
                </span>
            </div>    

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/4.png" alt="" className="mb20"/>
                    <h4>WalletConnect</h4>
                </span>
            </div>

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/5.png" alt="" className="mb20"/>
                    <h4>Coinbase Wallet</h4>
                </span>
            </div>

            <div className="col-lg-2 col-sm-4 col-6 mb30">
                <span className="box-url">
                    <img src="./img/wallet/6.png" alt="" className="mb20"/>
                    <h4>Arkane</h4>
                </span>
            </div>                                       
        </div>
      </section>*/}


      {/*<section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-double"></div>
          <div className='col-lg-12'>
              <h2>Top Sellers</h2>
          </div>
          <div className='col-lg-12'>
            <AuthorList/>
          </div>
        </div>
      </section>

      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-double"></div>
          <div className='col-lg-12 mb-2'>
              <h2>Hot NFTs in Last 7 Days</h2>
          </div>
            <div className='col-lg-12'>
              <CarouselCollection/>
            </div>
          </div>
      </section>

    */}
      <br />
      <Footer />

    </div>
  )

};
export default Homeone;