import React, { useState } from "react";
import { Router, Location, Redirect } from "@reach/router";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Header from "./menu/header";
import Home from "./pages/home";
import Home1 from "./pages/home1";
import Home2 from "./pages/home2";
import Explore from "./pages/explore";
import Explore2 from "./pages/explore2";
import Helpcenter from "./pages/helpcenter";
import Rangking from "./pages/rangking";
import Colection from "./pages/colection";
import ItemDetail from "./pages/ItemDetail";
import Author from "./pages/Author";
import Wallet from "./pages/wallet";
import Login from "./pages/login";
import LoginTwo from "./pages/loginTwo";
import Register from "./pages/register";
import Price from "./pages/price";
import Works from "./pages/works";
import News from "./pages/news";
import Create from "./pages/create";
import Auction from "./pages/Auction";
import Activity from "./pages/activity";
import Contact from "./pages/contact";
import ElegantIcons from "./pages/elegantIcons";
import EtlineIcons from "./pages/etlineIcons";
import FontAwesomeIcons from "./pages/fontAwesomeIcons";
import Accordion from "./pages/accordion";
import Alerts from "./pages/alerts";
import Progressbar from "./pages/progressbar";
import Tabs from "./pages/tabs";

import { createGlobalStyle } from "styled-components";
import { observer , Provider } from "mobx-react";
import GlobalStateProvider from "./Stores/GlobalStateProvider";
import { hookstate ,useHookstate} from '@hookstate/core';
import Profile from "./pages/profile";



export const globalState = hookstate({
    walletAddress : '0x0',
    isLogin : false,
    chainID : 97,
    provider : null,
    signer : null
});


const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
    React.useEffect(() => window.scrollTo(0, 0), [location]);
    return children;
};

const PosedRouter = ({ children }) => (
    <Location>
        {({ location }) => (
            <div id="routerhang">
                <div key={location.key}>
                    <Router location={location}>{children}</Router>
                </div>
            </div>
        )}
    </Location>
);

export const GlobalContext = React.createContext(null);

const App = () =>{

const [walletAddress,setWalletAddress] = useState("0x0")
const [isLogin,setIsLogin] = useState(false);


 return(
    <div className="wraper">
      

      <GlobalContext.Provider value={{walletAddress:walletAddress , setWalletAddress:setWalletAddress , isLogin:isLogin , setIsLogin:setIsLogin}}>
          
            <GlobalStyles />
            {/* <Header /> */}
      
      
        <PosedRouter>
           
           
            <ScrollTop path="/">
                {/* <Home2 exact path="/"/> */}
                {/* <Profile exact path="/profile"/> */}
                {/* <Home1 path="/home1" />
                <Home path="/home2" /> */}
                {/* <Explore path="/explore" /> */}
                {/* <Explore2 path="/explore2" /> */}
                {/* <Helpcenter path="/helpcenter" /> */}
                {/* <Rangking path="/rangking" /> */}
                {/* <Colection path="/colection" /> */}
                {/* <ItemDetail path="/ItemDetail" /> */}
                {/* <Author path="/Author" /> */}
                {/* <Wallet path="/wallet" />
                <Login path="/login" />
                <LoginTwo path="/loginTwo" />
                <Register path="/register" /> */}
                <Price exact path="/" />
                {/* <Works path="/works" />
                <News path="/news" />
                <Create path="/create" />
                <Auction path="/Auction" />
                <Activity path="/activity" />
                <Contact path="/contact" />
                <ElegantIcons path="/elegantIcons" />
                <EtlineIcons path="/etlineIcons" />
                <FontAwesomeIcons path="/fontAwesomeIcons" />
                <Accordion path="/accordion" />
                <Alerts path="/alerts" />
                <Progressbar path="/progressbar" />
                <Tabs path="/tabs" /> */}
            </ScrollTop>
        </PosedRouter>
        <ScrollToTopBtn />
        </GlobalContext.Provider>
    </div>
)
 };
export default App;
