export const RARE_NFTS = [18,103,135,140,230,250,292,765,955,965,976,1059,1222,1248,1761,2092,2113
    ,2159,2219,2350,2441,2581,2621,2654,2692,2760,2886,2930,3698,3875,3957,4181,4636,4781,4830,172,192,
    247,725,745,850,1169,1282,1371,1400,1696,1708,1749,1892,1959,2301,2409,2503,2540,2622,2623,2628,2661
    ,2693,2762,2821,2943,2980,3107,3130,3136,3325,3402,2461,3496,3642,3684,3693,3837,3972,4013,4092,4184,
    4321,4449,4464,4625,4627,4689,4995,28,440,522,738,940,1012,1177,1387,1442,1573,1666,1699,1721,1815,
    1929,1979,2068,2182,2294,2353,2535,2549,2588,2658,2698,2717,2730,2780,3094,3227,3268,3338,3406,3451,
    3473,3494,3507,3535,3779,4067,4085,4202,4216,4217,4360,4461,4466,4541,4964,4997,208,218,471,676,692,
    743,817,828,840,979,1245,1325,1590,1591,1731,1775,1862,1878,2008,2128,2371,2457,2544,2737,2783,2962,
    3049,3323,3533,3579,3604,3879,3918,4049,4084,4170,4314,4400,4413,4430,4556,4632,4635,4669,4709,4779,
    4874,4912,4985,4988,64,98,104,134,154,277,478,598,737,898,1076,1116,1263,1279,1331,1360,1380,1425,
    1476,1554,1672,1822,1865,2048,2133,2223,2242,2461,2487,2489,2832,2983,3211,3318,3453,3495,3550,3659,
    3807,3937,4052,4153,4154,4169,4286,4482,4566,4826,4839,10,17,22,76,164,215,329,408,433,
    444,464,492,518,555,602,660,707,708,726,747,790,854,909,930,960,1032,1054,1073,1080,1109,1178,1215,
    1317,1330,1342,1356,1427,1428,1434,1445,1467,1469,1512,1513,1514,1632,1684,1760,1760,1786,1802,1811,
    1852,1988,2036,2082,2083,2098,2110,2121,2148,2178,2270,2296,2321,2335,2380,2397,2423,2440,2475,
    2519,2574,2649,2741,2798,2803,2900,2945,2978,3037,3053,3096,3099,3102,3115,3118,3180,3242,3251,
    3328,3333,3346,3349,3350,3367,3373,3382,3384,3399,3458,3552,3574,3581,3645,3740,3742,3794,3833,
    3836,3859,3878,3911,3931,3978,3996,4010,4087,4092,4160,4175,4221,4223,4237,4241,4242,4309,4313,4332,
    4338,4356,4386,4433,4459,4481,4499,4517,4570,4575,4577,4579,4609,4662,4691,4694,4716,4799,4898,4907,4965,4970]


export const ULTRA_RARE_NFTs = [361,1953,3274,4322,4532,493,1398,1918,3764,4773,333,1093,2789,3865,4551]


