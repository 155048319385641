import React, { useContext } from 'react';
import Pricing from '../components/pricing';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { GlobalContext } from 'components/app';
import { Redirect } from '@reach/router';
import { ethers } from "ethers";
import SliderMainParticle from '../components/SliderMainParticle';
import CharacterNFTABI from '../../ContractABI/CharacterNFTABI'
import { ULTRA_RARE_NFTs, RARE_NFTS } from 'Data/NFTData';
import NFTCard from '../pages/profile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
  }
`;

const Price = () => {
  var provider = new ethers.providers.Web3Provider(window.ethereum);
  var signer = provider.getSigner();
  var contract = new ethers.Contract(
    "0x9ee70fef1886890677f4c03e86508185e888b994",
    CharacterNFTABI,
    signer
  );

  const loadData = async () => {
    let b = {
      "chain": "binance",
      "network": "mainnet",
      "account": walletAddress,
      "contract": "0x9ee70fef1886890677f4c03e86508185e888b994"
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(b)
    };
    var response = await fetch('https://api.gaming.chainsafe.io/evm/all721', requestOptions);
    const data = await response.json();
    const DataJSON = JSON.parse(data.response);

    console.log(DataJSON);

    // setJSONData(DataJSON);
  }
  //loadData();
  const { isLogin, setIsLogin, walletAddress, setWalletAddress } = useContext(GlobalContext);
  console.log(isLogin)

  const ConnectWithWallet = async () => {
    console.log("Inside already");


    try {
      if (window.ethereum.chainId === `0x${Number(56).toString(16)}`) {

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        setWalletAddress(accounts[0]);
        setIsLogin(true);
        window.ethereum.on('accountsChanged', setAccount);



        window.ethereum.on('accountsChanged', setAccount);


      } else {
        try {
          if (!window.ethereum) throw new Error("No crypto wallet found");
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: `0x${Number(56).toString(16)}`

              }
            ]
          });
        } catch (err) {
          console.log(err)
        }
      }
    } catch (err) {
      console.log(err);
    }

    return (null);
  }
  const MintRareNFT = async () => {


    //a.filter(x => !b.includes(x))
    // .concat(b.filter(x => !a.includes(x)));
    let mintedNFTs = await contract.getAllMinted();
    let result = [];
    // let result = Number(mintedNFTs);
    for (let i = 0; i < mintedNFTs.length; i++) {
      result.push(Number(mintedNFTs[i]._hex))
    }
    let remaining = RARE_NFTS.filter(x => !result.includes(x))
    const randomID = remaining[Math.floor(Math.random() * remaining.length)]
    console.log(randomID);
    try{
    const reciept = await contract.mintNFT(randomID, { value: ethers.utils.parseEther("1.25") });
    console.log(reciept);
    if (reciept.hash) {
      let url = 'https://bscscan.com/tx/'+reciept.hash
      toast.success(<>
        NFT minted successfully please refer to <a target="_blank" href={url} >Transaction Hash</a> for more details.
      </>, {
        position: "bottom-center",
        //autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
    }catch(e){
      toast.error("Something went wrong ! Please try again later", {
        position: "bottom-center",
        //autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }


  }

  const MintCommonNFT = async () => {


    //a.filter(x => !b.includes(x))
    // .concat(b.filter(x => !a.includes(x)));
    let allIDs = [];
    for(let i=1;i<=5000;i++){
      allIDs.push(i);
    }
    
    allIDs = allIDs.filter(x => !RARE_NFTS.includes(x))
    allIDs = allIDs.filter(x => !ULTRA_RARE_NFTs.includes(x))
    let mintedNFTs = await contract.getAllMinted();
    let result = [];
    // let result = Number(mintedNFTs);
    for (let i = 0; i < mintedNFTs.length; i++) {
      result.push(Number(mintedNFTs[i]._hex))
    }
    allIDs = allIDs.filter(x => !result.includes(x))
    console.log(allIDs);
    let randomID = allIDs[Math.floor(Math.random() * allIDs.length)]
    console.log(randomID);
    //let totalMintedHEX = await contract.TotalMinted(randomID);
   // let totalMinted = Number(totalMintedHEX);
  //  while(totalMinted===5) {
   //    randomID = allIDs[Math.floor(Math.random() * allIDs.length)]
   //    totalMintedHEX = await contract.TotalMinted(randomID);
   //    totalMinted = Number(totalMintedHEX);
   // }

    try{
    const reciept = await contract.mintNFT(randomID, { value: ethers.utils.parseEther("0.15") });
    console.log(reciept);
    if (reciept.hash) {
      let url = 'https://bscscan.com/tx/'+reciept.hash
      toast.success(<>
        NFT minted successfully please refer to <a target="_blank" href={url} >Transaction Hash</a> for more details.
      </>, {
        position: "bottom-center",
        //autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
    }catch(e){
      toast.error("Something went wrong ! Please try again later", {
        position: "bottom-center",
        //autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
    


  }
  const setAccount = (NewAccount) => {
    setWalletAddress(NewAccount)

  }
  // if(!isLogin) {return(<Redirect to="/"/>)}
  return (
    <div>
      {/* <GlobalStyles /> */}
      <section className='jumbotron breadcumb no-bg'>
        <div className='container'>
          <div className='row m-10-hor'>
            <div className='col-12 mt-4 text-center'>
              <h1>Mint Hamster Character</h1>
            </div>
          </div>
        </div>
      </section>



      {
        (isLogin) &&
        <>
          <section className='container'>
            <div className="row sequence">

              <div className="col-lg-6 col-md-6 col-sm-12 sq-item wow">
                <div className="pricing-s1 mb30">
                  <div className="top">
                    <h2>Mint Common NFT</h2>
                    <p className="plan-tagline">Price 0.15 BNB</p>
                  </div>
                  <div className="mid text-light bg-color">
                    <p className="price">

                      <img className='img-fluid' src="/img/common-animation.gif" />

                    </p>
                  </div>

                  <div className="bottom">

                  </div>

                  <div className="action">
                    <button style={{ display: 'inline', marginTop: '30px' }} onClick={() => MintCommonNFT()} className="btn-main">

                      <span >Mint Now</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 sq-item wow">
                <div className="pricing-s1 mb30">
                  <div className="top">
                    <h2>Mint Rare NFT</h2>
                    <p className="plan-tagline">Price 1.25 BNB</p>
                  </div>
                  <div className="mid text-light bg-color">
                    <p className="price">
                      <img className='img-fluid' src="/img/hamster-rare.gif" />
                    </p>
                  </div>
                  <div className="bottom">

                  </div>

                  <div className="action">
                    <button style={{ display: 'inline', marginTop: '30px' }} onClick={() => MintRareNFT()} className="btn-main">

                      <span >Mint Now</span>
                    </button>
                  </div>
                </div>
              </div>


            </div>
          </section>
          <Footer />
        </>
      }

      {
        (!isLogin) &&
        <>
          <div className="jumbotron no-bg">
            <SliderMainParticle />
          </div>
          <div className='text-center'>
            <button style={{ display: 'inline', marginTop: '30px' }} onClick={() => ConnectWithWallet()} className="btn-main">
              <i className="icon_wallet_alt"></i>
              <span >Connect Wallet</span>
            </button>
          </div>
        </>
      }
      <ToastContainer
        position="bottom-center"
        autoClose={25000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>

  )
}
export default Price;